body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal {
  z-index: 9998;
}

.modal-title {
  color: #219ebc;
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  border: solid 2px #073e8d;
}

.form-check label {
  margin-left: 15px;
  font-size: 13px;
  padding-top: 5px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.text-trans-cap {
  text-transform: capitalize;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.printted-label {
  color: #333333 !important;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  min-width: 70px;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.nav-justified .nav-item .nav-link {
  color: #073e8d;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.color-white {
  color: #ffffff;
}

.RQField {
  color: red
}

.border-red2 {
  border: 2px solid red;
  border-radius: 5px;
}

/* .App {
  background-image: url(../public/Images/export-background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
} */
/* CSS */
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #9b9d9f;
  border-color: #0d6efd;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.container,
.container-fluid {
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 100vh;
}

.css-1xc3v61-indicatorContainer {
  padding: 2px !important;
  z-index: 9999;
}

.css-1fdsijx-ValueContainer {
  padding: 0px 3px !important;
  font-weight: 600;
}

.css-13cymwt-control {
  min-height: 25px !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

.form-control,
.form-select,
.css-13cymwt-control {
  font-weight: 600;
  padding: 0.1rem;
  /* font-size: 0.8rem; */
}

/* .css-13cymwt-control{
  max-height: 15px;
  line-height: 5px;
} */
.css-t3hrcr-Table th {
  color: #219ebc;
}

.edditable-table-colum-input input {
  border: 2px solid #219ebc;
  border-radius: 5px;
}

.btn-primary {
  background-color: #219ebc;
  font-weight: 600;
  padding: 8px;
  line-height: 15px;
  border: #023047;
  min-width: 70px;
  transition: 0.8s;
}

.btn-primary:hover {
  background-color: #fb8500;
  border: #023047;
  color: #023047;
  transform: scale(1.1);
}

.form-check-input:checked {
  background-color: #fb8500;
  border-color: #fb8500;
}

.login-page-container {
  background: url(../public/Images/export-background.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  box-shadow: rgb(0 0 0 / 39%) 0px 3px 4px;
}

.register-link:hover {
  transform: scale(1.1);
  transform: 0.8s;
}

.register-link {
  color: #073e8d;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  transition: 0.8s;
}


.topbar {
  min-height: 30px;
  margin-bottom: 15px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 3px solid #073e8d;
  box-shadow: rgb(0 0 0 / 39%) 0px 3px 4px;
  position: fixed;
  z-index: 9995;
  margin-bottom: 50px;
}

.ico-nline {
  display: flex;
  text-align: right;
}

.ico-nline .icon-item {
  position: relative;
}

.ico-nline .icon-item .badge {
  position: absolute;
  top: -3px;
  right: -5px;
  padding: 4px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}


.navbar {
  background: rgb(2, 48, 71);
  background: linear-gradient(175deg,
      rgba(2, 48, 71, 0.5214460784313726) 35%,
      rgba(33, 158, 188, 0.49343487394957986) 59%);
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  font-weight: 600;
}

.table {
  color: #073e8d;
  --bs-table-hover-color: #219ebc;
}

.table-responsive,
.mt-3.bottom-border.pb-3,
.mt-4.bottom-border.pb-3 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: rgb(0 0 0 / 39%) 0px 1px 4px;
  padding: 8px !important;
  border-radius: 15px;
  margin-top: 5px !important;
}

.invoice-View {
  --bs-gutter-x: .2rem;
}

.form-box {
  box-shadow: rgb(0 0 0 / 39%) 0px 1px 4px;
  padding: 7px 15px;
  border-radius: 15px;
  border-bottom: 5px Solid #fb8500;
}

:root {
  --bs-table-hover-font-weight: 600;
}

.table-hover tr:hover {
  font-weight: var(--bs-table-hover-font-weight);
}

table td {
  cursor: pointer;
}

.table> :not(caption)>*>* {
  box-shadow: inset 0 0 0 9999px #ffffff;
  font-weight: 400;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
  min-width: 150px;
}

footer {
  background: rgb(2, 48, 71);
  background: linear-gradient(234deg,
      rgba(2, 48, 71, 0.9214460784313726) 66%,
      rgba(33, 158, 188, 0.89343487394957986) 100%);
}

.form-label,
.form-check-label {
  color: #073e8d;
  font-weight: 600;
}

.bottom-border {
  border-bottom: 5px Solid #fb8500;
}

.footer-text {
  color: #fb8500 !important;
  padding-top: 3px;
}

/* tempary Home Page */

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 15px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 39%) 0px 3px 4px;
  border-bottom: 5px solid #fb8500;
  border-top: 5px solid #fb8500;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 12px;
  font-weight: 400;
  color: rgb(34, 34, 34);
}

/*=============  pagination Css ================== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background: #f6f6f6;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.br-30 {
  border-radius: 30px;
}

.container .title {
  font-size: 40px;
  font-weight: 600;
  margin: 40px 0;
}

.blog-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  grid-gap: 30px;
  margin-top: 20px;
}

.blog-post {
  position: relative;
  height: 480px;
}

.form-label {
  margin-bottom: 0.2rem;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}

.blog-post .cover-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.blog-post .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog-post .description {
  font-size: 16px;
  color: #828282;
  margin-bottom: 10px;
}

.blog-post .card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.card-details .lh-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-details .date {
  font-size: 14px;
  color: #828282;
}

.blog-post .read-more {
  font-size: 16px;
  font-weight: 600;
  color: #00a8ff;
  text-decoration: none;
  margin-top: 10px;
}

.blog-post .read-more:hover {
  text-decoration: underline;
}

.blog-post .author-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

ul {
  list-style: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
}

.pagination .page-number {
  font-size: 16px;
  font-weight: 600;
  color: #00a8ff;
  /* background: #fff;
  padding: 10px 20px; */
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.pagination .page-number:hover {
  color: #023047;
  /* background: #00a8ff; */
}

.pagination .active {
  color: #023047;
  /* background: #00a8ff; */
}

.pagination .active:hover {
  color: #00a8ff;
  background: #023047;
}

/* navigation toggle */
.toggle-button {
  position: fixed;
  padding: 0;
  z-index: 9999;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 13px;
  top: 10px;
  left: 0;
}

.navigation,
col {
  transition-delay: 0.8s;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-name: slideFromRight;
  animation-fill-mode: both;
}

.breadcromb .nav-link {
  padding: 0;
  display: contents;
}

.breadcromb .base-link {
  color: #073e8d;
  font-weight: 600;
  font-size: 1rem;
}

.breadcromb .page-name {
  color: #219ebc;
  font-weight: 600;
  font-size: 1.2rem;
}

.nav-sec {
  transition-delay: 0.8s;
  animation-duration: 0.8s;
}

.submenu.open {
  animation: slideFromRight 700ms;
  display: block;
}

.submenu.close {
  animation: slideaway 700ms;
  display: none;
}

.submenu-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #310909 transparent transparent transparent;
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.arrow-icon {
  transition: transform 0.5s ease-in-out;
}

.sidebar.hide {
  animation: slideaway 700ms;
  display: none;

}

.sidebar.show {
  animation: slideToLeft;
  width: 250px;
  max-height: 95vh;
  overflow-y: scroll;
}

/* Set scrollbar width and track color */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

/* Set scrollbar track on the right side */
.sidebar::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

/* Set scrollbar handle */
.sidebar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 6px;
}

.topbar .user-action,
.show>.btn-primary.dropdown-toggle {
  background-color: transparent;
  padding: 0;
}

.topbar .dropdown-menu {
  transform: 0.8s;
  border: none;
  box-shadow: rgb(0 0 0 / 39%) 3px 3px 4px;
  min-width: 200px;
  padding: 15px;

}

.topbar .custom-dropdown .dropdown-menu {
  border-radius: 10px;
  /* Adjust the border radius to control the dialog box shape */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Add shadow for a raised effect */
  padding: 10px;
  /* Adjust padding as needed */
  /* Add other styling properties like background color, text color, etc. */
}

.topbar .custom-dropdown.dropdown {
  max-width: 35px;
}

.topbar .dropdown-item {
  border-radius: 15px;
  color: #073e8d;
  font-weight: 600;
  transition: 0.8s;
}

.topbar .dropdown-item:hover {
  transition: 0.8s;
  transform: scale(1.1);

}

.topbar #notification_action:hover {
  transform: initial;
}

.topbar .user-action:focus {
  box-shadow: none;
  outline: none;
}

.topbar .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.topbar .dropdown-toggle::after {
  display: none;
}

@keyframes slideaway {
  from {
    display: block;
  }

  to {
    transform: translatex(-100%);
    opacity: 0;
  }
}

@keyframes slideToLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }

  to {
    width: 0;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }

  to {
    width: 100%;
  }

}

.sidebar {
  max-width: 250px;
  background-color: #333;
  color: #fff;
  overflow: hidden;
  min-height: 100vh;
  z-index: 999;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-right: 5px solid #073e8d;
  box-shadow: rgb(0 0 0 / 39%) 3px 3px 4px;
}

.sidebar ul {
  padding-left: 1rem;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}


.sidebar-nav li {}

.sidebar-nav a {
  text-decoration: none;
  color: #073e8d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #219ebc;
  color: #fff;
  margin-top: 1px;
  border-radius: 30px;
}

.sidebar-nav a .svg-inline--fa {
  height: 1.3em;
}

.sidebar-nav a:hover {
  background-color: #073e8d;
  color: #fb8500;
  font-weight: 600;
  transition: 0.8s;
}

.sidebar-nav .active-link {
  background-color: #073e8d;
  transition: 0.8s;
  color: #f6f6f6;
  box-shadow: rgb(0 0 0 / 39%) 0px 1px 4px;
  margin: 3px;
  border-bottom: 3px solid #fb8500;
}

.main-content {
  margin-top: 50px;

}

.submenu {
  animation: slideaway 1000ms;
  display: none;
}

.panel-area {
  max-width: calc(100% - 250px);
  /* background-image:url(../public/Images/export-background.jpg);  */
}

.panel-area .container-fluid {
  border-radius: 30px;
}

.chart-box {
  height: 350px;
  padding: 15px;
  border-radius: 15px;
  background-color: #ffffff;
  max-width: 49%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgb(0 0 0 / 39%) 0px 1px 4px;
  border-top: 5px solid #073e8d;
  border-bottom: 5px solid #073e8d;
}

.Full-width-chart {
  padding: 15px;
  border-radius: 15px;
  padding-top: 40px;
  height: 350px;
  margin-bottom: 50px;
  box-shadow: rgb(0 0 0 / 39%) 0px 1px 4px;
}

.report-col {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.report-button {
  flex-grow: 1;
  /* Allow buttons to grow and occupy available space */
}

.report-tile-label {
  color: #073e8d;
  font-weight: 600;
}

.report-page-titles {
  color: #073e8d;
  font-weight: 600;
  font-size: 1.5rem;
}

.booking-Vat-pecentage {
  left: 78%;
  max-width: 25px;
}

.booking-Vat-pecentage-mark {
  font-size: 18px;
  left: 80%;
}

.billTableView tr {
  padding: 5px 0 !important;
}

.billTableView td,
.billTableView th {
  max-height: 20px;
  /* Maximum height for td and th */
  overflow: hidden;
  /* Hide overflow content */
  line-height: 14px;
}

.billTableView td {
  background-color: white;
}

.billTableView .columnResizer {
  background-color: #1A4697;
  width: 5px;
  min-width: 5px;
  max-width: 5x;
  padding: 2px 0 2px 0;
  border: none;
  height: 15px;
  min-height: 15px;
  max-height: 15px;
  overflow: hidden;
  border-radius: 15px;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1054px;
  }
}

.css-1xc3v61-indicatorContainer {
  z-index: 9999;
}

.css-zp1egi-MenuPortal,
.css-qaj91z-MenuPortal {
  z-index: 9999 !important;
  /* Adjust this value as needed */

}